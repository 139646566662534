var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box form-box has-background-white" }, [
    _c(
      "div",
      { staticClass: "enso-form" },
      [
        _c("enso-form", {
          ref: "form",
          scopedSlots: _vm._u([
            {
              key: "password",
              fn: function(props) {
                return [
                  !props.field.meta.hidden
                    ? _c(
                        "form-field",
                        _vm._b(
                          {
                            on: {
                              focus: function($event) {
                                props.field.meta.readonly = false
                              },
                              blur: function($event) {
                                props.field.meta.readonly = true
                              },
                              input: function($event) {
                                _vm.password = $event.target.value
                              }
                            }
                          },
                          "form-field",
                          props,
                          false
                        )
                      )
                    : _vm._e(),
                  _c("password-strength", {
                    staticClass: "has-margin-top-small",
                    attrs: { password: props.field.value }
                  })
                ]
              }
            },
            {
              key: "password_confirmation",
              fn: function(props) {
                return [
                  !props.field.meta.hidden
                    ? _c(
                        "form-field",
                        _vm._b(
                          {
                            on: {
                              focus: function($event) {
                                props.field.meta.readonly = false
                              },
                              blur: function($event) {
                                props.field.meta.readonly = true
                              },
                              input: function($event) {
                                _vm.passwordConfirmation = $event.target.value
                              },
                              keydown: function($event) {
                                return _vm.$emit("update")
                              }
                            }
                          },
                          "form-field",
                          props,
                          false
                        )
                      )
                    : _vm._e()
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }