var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { "enter-active-class": "zoomIn", "leave-active-class": "zoomOut" }
    },
    [
      _c("div", { staticClass: "password-strength" }, [
        _vm.password
          ? _c(
              "svg",
              {
                staticClass: "animated",
                attrs: {
                  width: "100%",
                  height: "5",
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              _vm._l(5, function(i) {
                return _c("rect", {
                  key: i,
                  attrs: {
                    width: "20%",
                    height: "4",
                    "stroke-width": "4",
                    x: 0 + (i * 20 - 20) + "%",
                    stroke: i <= _vm.score() + 1 ? "green" : "orangered"
                  }
                })
              }),
              0
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }