<template>
    <div class="box form-box has-background-white">
        <div class="enso-form">
            <enso-form ref="form">
                <template v-slot:password="props">
                    <form-field v-bind="props"
                        @focus="props.field.meta.readonly = false"
                        @blur="props.field.meta.readonly = true"
                        @input="password = $event.target.value"
                        v-if="!props.field.meta.hidden"/>
                    <password-strength class="has-margin-top-small"
                        v-bind:password="props.field.value"/>
                </template>
                <template v-slot:password_confirmation="props">
                    <form-field v-bind="props"
                        @focus="props.field.meta.readonly = false"
                        @blur="props.field.meta.readonly = true"
                        @input="passwordConfirmation = $event.target.value"
                        @keydown="$emit('update');"
                        v-if="!props.field.meta.hidden"/>
                </template>
            </enso-form>
        </div>
    </div>
</template>

<script>
import { EnsoForm, FormField } from '@enso-ui/forms/bulma';
import PasswordStrength from '@components/ui/utility/PasswordStrength.vue';

export default {
    name: 'Create',

    components: { EnsoForm, FormField, PasswordStrength },

    data: () => ({
        password: null,
        passwordConfirmation: null,
    }),
};
</script>
